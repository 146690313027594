<template>
  <v-dialog persistent max-width="700" :value="value">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-form ref="form">
          <v-row dense>
            <v-col cols="9">
              <v-text-field
                label="Ruc / CI"
                autocomplete="off"
                ref="repre1"
                :rules="rules.number"
                v-model="data.ruccontacto"
                placeholder="Ingrese el Ruc / CI"
                :hint="
                  !editable
                    ? hintSearch
                      ? 'Presione en la lupa para buscar el contacto'
                      : ''
                    : ''
                "
                :append-outer-icon="
                  !editable ? (hintSearch ? 'mdi-magnify' : '') : ''
                "
                @click:append-outer="searchFirebase(data.ruccontacto)"
                @keyup.enter="next(1, data.ruccontacto)"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="Digito Verificador"
                autocomplete="off"
                ref="repre2"
                :rules="rules.number"
                v-model="data.docucontactos"
                placeholder="Ingrese el DV"
                @keyup.enter="next(2, data.docucontactos)"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Representante / Razon Social"
                autocomplete="off"
                ref="repre3"
                :rules="rules.text"
                v-model="data.desccontacto"
                placeholder="Ingrese el nombre del Representante"
                @keyup.enter="next(3, data.desccontacto)"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                :loading="isNacionalidadLoading"
                v-model="data.idnacionalidad"
                :items="getNacionalidad"
                ref="repre4"
                item-value="idnacionalidad"
                item-text="descnacionalidad"
                return-object
                chips
                small-chips
                label="Nacionalidad"
                @keyup.enter="next(4, '-'), ($refs.repre4.isMenuActive = false)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                :loading="isDepartamentoLoading"
                v-model="data.iddepartamento"
                :items="getDepartamento"
                ref="repre5"
                :search-input.sync="searchState"
                item-value="iddepartamento"
                item-text="descripcion"
                return-object
                chips
                small-chips
                label="Departamento"
                @keyup.enter="next(5, '-'), ($refs.repre5.isMenuActive = false)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                :loading="isCiudadLoading"
                v-model="data.idciudad"
                :items="getCiudad"
                ref="repre6"
                :search-input.sync="searchCity"
                item-value="idciudad"
                item-text="descciudad"
                return-object
                chips
                small-chips
                label="Ciudad"
                @keyup.enter="next(6, '-'), ($refs.repre5.isMenuActive = false)"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <c-btn-crud-close @click="cancel()"></c-btn-crud-close>
        <v-spacer></v-spacer>
        <c-btn-crud-done ref="repre6" @click="save()"></c-btn-crud-done>
      </v-card-actions>
      <c-overlay :value="isRepresentanteLoading"></c-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
    },
  },
  mounted() {
    if (this.editable) this.data = JSON.parse(JSON.stringify(this.props));
    this.fetchNacionalidad();
    setTimeout(() => this.next(0, "-"), 200);
  },
  watch: {
    getRepresentanteFirebase(val) {
      if (val != null) this.asignarContactoDesdeOrigen(val);
    },
    searchCity(val) {
      if (val === null || val === undefined || val === "") return null;
      this.fetchCiudad({
        search: val,
        iddepartamento: this.data.iddepartamento.iddepartamento,
        idnacionalidad: this.data.idnacionalidad.idnacionalidad,
      });
    },
    searchState(val) {
      if (val === null || val === undefined || val === "") return null;
      this.fetchDepartamento({
        search: val,
        idnacionalidad: this.data.idnacionalidad.idnacionalidad,
      });
    },
  },
  computed: {
    ...mapGetters("representante", [
      "isRepresentanteLoading",
      "getRepresentanteFirebase",
    ]),
    ...mapGetters("ciudad", ["isCiudadLoading", "getCiudad"]),
    ...mapGetters("departamento", ["isDepartamentoLoading", "getDepartamento"]),
    ...mapGetters("nacionalidad", ["isNacionalidadLoading", "getNacionalidad"]),
    title() {
      return this.editable
        ? "Modificar Representante"
        : "Registrar Representante";
    },
    hintSearch() {
      return this.data.ruccontacto.toString().length > 4 ? true : false;
    },
  },
  methods: {
    ...mapActions("representante", [
      "setRepresentante",
      "fetchRepresentante",
      "fetchRepresentanteFirebase",
      "setRepresentanteUpdate",
    ]),
    ...mapActions("ciudad", ["fetchCiudad"]),
    ...mapActions("departamento", ["fetchDepartamento"]),
    ...mapActions("nacionalidad", ["fetchNacionalidad"]),

    cancel() {
      this.$emit("input", false);
      this.$emit("editable", false);
    },
    searchFirebase(ruc) {
      if (isNaN(ruc) || ruc.toString().length < 5) return null;
      this.fetchRepresentanteFirebase(ruc);
    },
    asignarContactoDesdeOrigen(item) {
      switch (item.origin) {
        case "db":
          this.data = JSON.parse(JSON.stringify(item.data));
          this.$emit("editable", true);
          break;
        case "firebase":
          this.data = { ...this.data, ...item.data };
          break;

        case "backend":
          this.data = { ...this.data, ...item.data };
          break;

        default:
          console.log("Caso desconocido");
          break;
      }
    },
    next(id, data) {
      if (data != "")
        try {
          this.$refs[`repre${id + 1}`].focus();
        } catch (error) {
          this.$refs[`repre${id + 1}`].$el.focus();
        }
    },
    async save() {
      if (!this.$refs.form.validate()) return null;
      const response = this.editable
        ? await this.setRepresentanteUpdate({
            data: this.data,
            id: this.data.idcontacto,
          })
        : await this.setRepresentante(this.data);
      if (response.success) {
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
        setTimeout(() => this.next(0, "-"), 20);
        this.fetchRepresentante();
        if (this.editable) this.cancel();
      }
    },
  },
  data: () => ({
    rules: {
      number: [
        (v) => !!v || "Este campo es obligatorio",
        (v) => !isNaN(v) || "Este campo es numerico",
      ],
      text: [(v) => !!v || "Este campo es obligatorio"],
    },
    searchCity: "",
    searchState: "",
    data: {
      idcontacto: 0,
      desccontacto: "",
      correo: null,
      direcontacto: null,
      fechcontacto: null,
      idciudad: {
        idciudad: "",
      },
      iddepartamento: {
        iddepartamento: "",
      },
      idnacionalidad: {
        idnacionalidad: "",
      },
      latitud: null,
      longitud: null,
      ruccontacto: "",
      telecontacto: null,
    },
    default: {
      idcontacto: 0,
      desccontacto: "",
      correo: null,
      direcontacto: null,
      fechcontacto: null,
      idciudad: {
        idciudad: "",
      },
      iddepartamento: {
        iddepartamento: "",
      },
      idnacionalidad: {
        idnacionalidad: "",
      },
      latitud: null,
      longitud: null,
      ruccontacto: "",
      telecontacto: null,
    },
  }),
};
</script>

<style></style>
