<template>
  <c-dialog-confirmation
    :value="value"
    @cancel="cancel()"
    @done="deletePlan()"
    :loading="isPagosLoading"
    >Desea eliminar el pago de <strong>{{ props.idcontacto.desccontacto }}</strong
    >?</c-dialog-confirmation
  >
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: Boolean,
    props: Object,
  },
  computed: {
    ...mapGetters("pagos", ["isPagosLoading"]),
  },
  methods: {
    ...mapActions("pagos", ["setPagosDelete", "fetchPagos"]),
    cancel() {
      this.$emit("input", false);
    },
    async deletePlan() {
      const response = await this.setPagosDelete(this.props.idpago_contrato);
      if (response.success) {
        this.cancel();
        this.fetchPagos();
      }
    },
  },
};
</script>

<style>
</style>