<template>
  <div>
    <v-data-table
      :loading="isUsersLoading"
      :headers="headers"
      :search="search"
      :items="active ? getUsersActive : getUsersInactive"
      :items-per-page="15"
    >
      <template v-slot:[`item.empresa`]="{ item }">
        <div>{{ getEmpresas(item.usuario) }}</div>
      </template>
      <template v-slot:[`item.options`]="{ item }">
        <v-btn
          v-if="active"
          color="primary"
          text
          small
          outlined
          dark
          @click="resetPassword(item)"
        >
          Reset Password
        </v-btn>
        <v-btn color="red" text small outlined dark @click="disableUser(item)">
          {{ active ? "Desabilitar" : "Habilitar" }}
        </v-btn>
      </template>
    </v-data-table>
    <ResetPassword v-if="dialog.reset" v-model="dialog.reset" :user="user" />
    <DisableUser
      v-if="dialog.disabled"
      v-model="dialog.disabled"
      :user="user"
      :active="active"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ResetPassword from "./ResetPassword";
import DisableUser from "./DisableUser";
export default {
  components: {
    ResetPassword,
    DisableUser,
  },
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    search: [String, Number],
  },
  data: () => ({
    headers: [
      {
        text: "Usuario",
        align: "start",
        value: "username",
      },
      {
        text: "Nombre",
        align: "start",
        value: "last_name",
      },
      { text: "Email", value: "email" },
      { text: "Empresa", value: "empresa" },
      { text: "Ultima Conexion", value: "last_login" },
      { text: "Opciones", value: "options", align: "end", sortable: false },
    ],
    dialog: {
      reset: false,
      disabled: false,
    },
    user: {},
  }),
  mounted() {
    this.active ? this.fetchUsersActive() : this.fetchUsersInactive();
  },
  computed: {
    ...mapGetters("users", [
      "getUsersActive",
      "getUsersInactive",
      "isUsersLoading",
    ]),
  },
  methods: {
    ...mapActions("users", ["fetchUsersActive", "fetchUsersInactive"]),
    resetPassword(item) {
      this.dialog.reset = true;
      this.user = JSON.parse(JSON.stringify(item));
    },
    disableUser(item) {
      this.dialog.disabled = true;
      this.user = JSON.parse(JSON.stringify(item));
    },
    getEmpresas(item) {
      if(item.length === 0) return '';
      return item.reduce((acc, cur, index) => {
        if (index === 0) return (acc = cur.idempresa.descempresa);
        return (acc = acc + ", " + cur.idempresa.descempresa);
      }, "");
    },
  },
};
</script>

<style>
</style>