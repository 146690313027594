<template>
  <v-dialog persistent max-width="900" :value="value">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-form ref="form">
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Descripcion"
                autocomplete="off"
                ref="modul1"
                :rules="rules"
                v-model="data.descrol"
                placeholder="Ingrese una descripcion"
                @keyup.enter="next(1, data.descripcion)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                :loading="isPlanesLoading"
                v-model="data.idplan.idplan"
                :items="getPlanes"
                ref="modul2"
                :rules="rules"
                item-value="idplan"
                item-text="descripcion"
                label="Plan"
                placeholder="Seleccione el Plan al que pertenece"
                @keyup.enter="next(2, data.idplan.idplan)"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
        <v-form ref="formDet">
          <v-row dense>
            <v-col cols="12">
              <v-autocomplete
                :loading="isFormulariosLoading"
                v-model="detalle.idformulario"
                :items="getFormularios"
                ref="modul3"
                item-value="idformulario"
                item-text="descformulario"
                return-object
                :rules="[(v) => !!v.idformulario || 'Seleccione un Formulario']"
                chips
                small-chips
                label="Seleccione el Formulario"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Funciones</th>
                      <th class="text-left">Activar</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, key, i) in detalle.idformulario
                        .permiso_json"
                      :key="i"
                    >
                      <td>{{ key }}</td>
                      <td>
                        <v-checkbox
                          v-if="!isNumber(item)"
                          class="mt-n1"
                          v-model="detalle.idformulario.permiso_json[key]"
                          color="red"
                          hide-details
                        ></v-checkbox>
                        <c-text-currency
                          v-else
                          dense
                          v-model="detalle.idformulario.permiso_json[key]"
                        >
                        </c-text-currency>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-btn block color="primary" @click="addTable()"
              >AGREGAR AL MODULO</v-btn
            >
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="headers"
                dense
                :items="data.roldet"
                :items-per-page="10000"
                hide-default-footer
                class="elevation-1"
              >
                <template v-slot:[`item.permiso`]="{ item }">
                  <div>{{ toText(item.permiso) }}</div>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <c-btn-crud-edit @click="edit(item)"></c-btn-crud-edit>
                  <c-btn-crud-delete
                    @click="deletRow(item)"
                  ></c-btn-crud-delete>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <c-btn-crud-close @click="cancel()"></c-btn-crud-close>
        <v-spacer></v-spacer>
        <c-btn-crud-done @click="save()"></c-btn-crud-done>
      </v-card-actions>
      <c-overlay :value="isModulosLoading"></c-overlay>

      <c-dialog-confirmation
        v-if="delet.dialog"
        :value="delet.dialog"
        @cancel="cancelDelete()"
        @done="confirnDelete()"
        >Desea quitar el formulario
        <strong>{{ delet.data.idformulario.descformulario }}</strong
        >?</c-dialog-confirmation
      >
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
    },
  },
  mounted() {
    if (this.editable) this.data = JSON.parse(JSON.stringify(this.props));
    this.fetchFormularios();
    this.fetchPlanes();
    setTimeout(()=>this.next(0, "-"),20);

  },
  computed: {
    ...mapGetters("modulos", ["isModulosLoading"]),
    ...mapGetters("formularios", ["getFormularios", "isFormulariosLoading"]),
    ...mapGetters("planes", ["getPlanes", "isPlanesLoading"]),
    title() {
      return this.editable ? "Modificar Modulo" : "Registrar Modulo";
    },
  },
  methods: {
    ...mapActions("formularios", ["fetchFormularios"]),
    ...mapActions("planes", ["fetchPlanes"]),
    ...mapActions("snackbar", ["setNotification"]),
    ...mapActions("modulos", [
      "setModulos",
      "fetchModulos",
      "setModulosUpdate",
    ]),

    isNumber(item) {
      if(item === "") return 'number'
      return typeof item === "number" ? true : false;
    },
    next(id, data) {
      if (data != "")
        try {
          this.$refs[`modul${id + 1}`].focus();
        } catch (error) {
          this.$refs[`modul${id + 1}`].$el.focus();
        }
    },

    getDetail(idformulario) {
      const detail = this.data.roldet.find(
        (x) => x.idformulario.idformulario === idformulario
      );
      return detail === undefined ? this.detalleDefault : detail;
    },
    insertDependeces() {
      if (this.detalle.idformulario.dependencias === null) return null;
      if (
        this.detalle.idformulario.permiso_json["Puede agregar"] ||
        this.detalle.idformulario.permiso_json["Puede editar"] ||
        this.detalle.idformulario.permiso_json["Puede listar"]
      )
        this.detalle.idformulario.dependencias.map((idform) => {
          let detalle = JSON.parse(JSON.stringify(this.getDetail(idform)));
          detalle.idformulario = this.getFormularios.find(
            (x) => x.idformulario === idform
          );
          detalle.idformulario.permiso_json["Puede listar"] = true;

          detalle.permiso === null
            ? (detalle.permiso = { "Puede listar": true })
            : (detalle.permiso["Puede listar"] = true);
          this.insertDetail(detalle);
        });
    },

    insertDetail(newValue) {
      const oldValue = this.data.roldet.find(
        (x) =>
          x.idformulario.idformulario === newValue.idformulario.idformulario
      );
      if (oldValue)
        this.data.roldet.splice(this.data.roldet.indexOf(oldValue), 1);
      this.data.roldet.push(newValue);
    },

    addTable() {
      if (!this.$refs.formDet.validate()) return null;
      let permiso = {};
      try {
        Object.keys(this.detalle.idformulario.permiso_json).forEach((key) => {
          if (this.detalle.idformulario.permiso_json[key])
            permiso = {
              ...permiso,
              ...{ [key]: this.detalle.idformulario.permiso_json[key] },
            };
        });
        this.detalle.permiso = JSON.parse(JSON.stringify(permiso));
        this.insertDependeces();
        this.insertDetail(this.detalle);
        this.detalle = JSON.parse(JSON.stringify(this.detalleDefault));
        this.next(2, "-");
      } catch (error) {
        console.log(error);
        this.setNotification({ type: "error", message: error.message });
      }
    },
    edit(item) {
      this.detalle.idformulario = JSON.parse(JSON.stringify(item.idformulario));
    },
    deletRow(item) {
      this.delet.dialog = true;
      this.delet.data = JSON.parse(JSON.stringify(item));
      this.delet.index = this.data.roldet.indexOf(item);
    },
    confirnDelete() {
      this.data.roldet.splice(this.delet.index, 1);
      this.delet = JSON.parse(JSON.stringify(this.deletDefault));
    },
    cancelDelete() {
      this.delet = JSON.parse(JSON.stringify(this.deletDefault));
    },
    cancel() {
      this.$emit("input", false);
    },
    async save() {
      if (!this.$refs.form.validate()) return null;
      const response = this.editable
        ? await this.setModulosUpdate({ data: this.data, id: this.data.idrol })
        : await this.setModulos(this.data);
      if (response.success) {
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
        this.fetchModulos();
        if (this.editable) this.cancel();
      }
    },
    toText(item) {
      if (item === null) return "No tiene permisos";
      let text = "";
      Object.keys(item).forEach((key, i) => {
        if (i === 0) return (text = key);
        text = text + ", " + key;
      });
      return text;
    },
  },
  data: () => ({
    rules: [(v) => !!v || "Este campo es obligatorio"],
    delet: {
      dialog: false,
      data: null,
      index: -1,
    },
    deletDefault: {
      dialog: false,
      data: null,
      index: -1,
    },
    data: {
      idrol: 0,
      descrol: "",
      idplan: {
        idplan: "",
      },
      roldet: [],
    },
    default: {
      idrol: 0,
      descrol: "",
      idplan: {
        idplan: "",
      },
      roldet: [],
    },
    detalle: {
      idformulario: "",
      permiso: null,
      idrol: 0,
      can_add: true,
      can_delete: true,
      can_update: true,
      can_view: true,
    },
    detalleDefault: {
      idformulario: "",
      permiso: null,
      idrol: 0,
      can_add: true,
      can_delete: true,
      can_update: true,
      can_view: true,
    },
    headers: [
      {
        text: "Formulario",
        align: "start",
        sortable: false,
        divider: true,
        value: "idformulario.descformulario",
      },
      {
        text: "Permisos",
        align: "end",
        sortable: false,
        value: "permiso",
      },
      {
        text: "Opciones",
        align: "end",
        sortable: false,
        value: "action",
      },
    ],
  }),
};
</script>

<style>
</style>