<template>
  <v-dialog persistent max-width="500" :value="value">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-form ref="form" @submit.prevent="next(1, data.descnacionalidad)">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                label="Descripcion"
                autocomplete="off"
                :rules="rules"
                ref="nacionalidad1"
                v-model="data.descnacionalidad"
                placeholder="Ingrese una descripcion"
                @keyup.enter="next(1, data.descnacionalidad)"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <c-btn-crud-close @click="cancel()"></c-btn-crud-close>
        <v-spacer></v-spacer>
        <c-btn-crud-done ref="nacionalidad2" @click="save()"></c-btn-crud-done>
      </v-card-actions>
      <c-overlay :value="isNacionalidadLoading"></c-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
    },
  },
  mounted() {
    if (this.editable) this.data = JSON.parse(JSON.stringify(this.props));
    setTimeout(() => this.next(0, "-"), 20);
  },
  computed: {
    ...mapGetters("nacionalidad", ["isNacionalidadLoading"]),
    title() {
      return this.editable
        ? "Modificar Nacionalidad"
        : "Registrar Nacionalidad";
    },
  },
  methods: {
    ...mapActions("nacionalidad", [
      "setNacionalidad",
      "fetchNacionalidad",
      "setNacionalidadUpdate",
    ]),
    cancel() {
      this.$emit("input", false);
    },
    next(id, data) {
      if (data != "")
        try {
          this.$refs[`nacionalidad${id + 1}`].focus();
        } catch (error) {
          this.$refs[`nacionalidad${id + 1}`].$el.focus();
        }
    },
    async save() {
      if (!this.$refs.form.validate()) return null;
      const response = this.editable
        ? await this.setNacionalidadUpdate({
            data: this.data,
            id: this.data.idnacionalidad,
          })
        : await this.setNacionalidad(this.data);
      if (response.success) {
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
        this.next(0,'-');
        this.fetchNacionalidad();
        if (this.editable) this.cancel();
      }
    },
  },
  data: () => ({
    rules: [(v) => !!v || "Este campo es obligatorio"],
    data: {
      idnacionalidad: 0,
      descnacionalidad: "",
    },
    default: {
      idnacionalidad: 0,
      descnacionalidad: "",
    },
  }),
};
</script>

<style>
</style>