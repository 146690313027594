<template>
  <v-btn text color="primary" fab outlined small @click="$emit('click')"
    ><v-icon>mdi-plus</v-icon></v-btn
  >
</template>

<script>
export default {};
</script>

<style>
</style>