import ApiService from './apiconfig.service'
import { TokenService } from '@/services/storage/tokenStorage.service'

const getError = (error) => {
    let errors = []
    Object.keys(error).forEach(key => {
        if (Array.isArray(error[key])) {
            errors.push(error[key][0]);
        } else {
            errors.push(error[key]);
        }
    })
    return errors;
}



export const post = async (url, data) => {
    const requestData = {
        method: 'POST',
        url: url,
        data: data,
        headers: {
            'Authorization': `Token ${TokenService.getToken()}`
        }

    }

    try {
        const response = await ApiService.customRequest(requestData);
        return {
            type: 'success',
            success: true,
            message: response.data.Success
        }
    } catch (error) {
        return {
            type: 'error',
            errors: getError(error.response.data),
            data: error.response.data,
            success: false,
            message: error.message
        }
    }
}

export const get = async (url) => {

    const requestData = {
        method: 'GET',
        url: url,
        headers: {
            'Authorization': `Token ${TokenService.getToken()}`
        }
    }
    try {
        const response = await ApiService.customRequest(requestData)
        return response.data
    } catch (error) {
        console.log(error)
        throw {
            type: 'error',
            success: false,
            message: error.message
        }
    }
}
export const put = async (url, data) => {
    const requestData = {
        method: 'PUT',
        url: url,
        data: data,
        headers: {
            'Authorization': `Token ${TokenService.getToken()}`
        }

    }
    try {
        const response = await ApiService.customRequest(requestData);
        return {
            type: 'success',
            success: true,
            message: response.data.Success
        }
    } catch (error) {
        return {
            type: 'error',
            success: false,
            errors: getError(error.response.data),
            message: error.message
        }
    }

}
export const del = async (url, params) => {
    const requestData = {
        method: 'DELETE',
        url: url,
        params: params,
        headers: {
            'Authorization': `Token ${TokenService.getToken()}`
        }

    }

    try {
        const response = await ApiService.customRequest(requestData)
        return {
            type: 'success',
            success: true,
            message: response.data.Success
        }
    } catch (error) {
        return {
            type: 'error',
            success: false,
            errors: getError(error.response.data),
            message: error.message
        }
    }
}


