<template>
  <v-dialog persistent max-width="900" :value="value">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-form
          ref="form"
          @submit.prevent="next(1, data.idcontacto.idcontacto)"
        >
          <v-row dense>
            <v-col cols="12" sm="9">
              <AutocompleteRepresentante
                v-model="data.idcontacto"
                ref="pago1"
                @keyup.native.enter="next(1, data.idcontacto.idcontacto)"
              />
            </v-col>
            <v-col cols="12" sm="3">
              <c-text-date
                label="Fecha"
                v-model="data.fecha"
              ></c-text-date>
            </v-col>
          </v-row>
        </v-form>
        <v-form ref="formDetalle" @submit.prevent="save()">
          <v-row dense>
            <v-col cols="12" md="3">
              <v-autocomplete
                :loading="isContractsLoading"
                :items="getContractsFilter"
                ref="pago2"
                v-model="detalle.idcontrato"
                item-value="idcontrato"
                item-text="descripcion"
                return-object
                :rules="[(v) => !!v || 'Seleccione un Contrato']"
                placeholder="Contrato a pagar"
                chips
                :error-messages="error.contrato"
                small-chips
                label="Seleccione el Contrato"
                @change="setPrecio(detalle.idcontrato)"
                @keyup.enter="next(2, detalle.idcontrato)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <c-text-currency
                label="Costo Mensual"
                autocomplete="off"
                :error-messages="error.contrato"
                readonly
                ref="pago3"
                :rules="rules"
                v-model="detalle.precio"
                @keyup.native.enter="next(3, detalle.precio)"
              />
            </v-col>
            <v-col cols="12" md="3">
              <c-text-currency
                label="Meses"
                autocomplete="off"
                ref="pago4"
                :rules="rules"
                v-model="detalle.cantidad"
                placeholder="Cantidad en meses"
                @keyup.native.enter="next(4, detalle.cantidad)"
              />
            </v-col>
            <v-spacer></v-spacer>
            <c-btn-crud-add ref="pago5" @click="addTable()"></c-btn-crud-add>
          </v-row>
        </v-form>
      </v-container>
      <v-data-table
        :headers="headers"
        :items="data.detalle"
        :items-per-page="99999999"
        hide-default-footer
      >
        <template v-slot:[`item.precio`]="{ item }">
          <div>{{ toCurrency(item.precio) }}</div>
        </template>
        <template v-slot:[`item.cantidad`]="{ item }">
          <div>{{ toCurrency(item.cantidad) }}</div>
        </template>
        <template v-slot:[`item.subtotal`]="{ item }">
          <div>{{ toCurrency(item.precio * item.cantidad) }}</div>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <c-btn-crud-edit @click="editRow(item)" />
          <c-btn-crud-delete @click="deleteRow(item)" />
        </template>
        <template slot="body.append">
          <tr class="black--text">
            <th class="subtitle-2">Total</th>
            <th></th>
            <th></th>
            <th class="subtitle-2 text-end">
              {{ toCurrency(total) }}
            </th>
            <th></th>
          </tr>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Comentario"
              v-model="data.comentario"
              autocomplete="off"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <c-btn-crud-close @click="cancel()"></c-btn-crud-close>
        <v-spacer></v-spacer>
        <c-btn-crud-done @click="save()"></c-btn-crud-done>
      </v-card-actions>
      <c-overlay :value="isPagosLoading"></c-overlay>
      <c-dialog-confirmation
        v-model="dialog.delete"
        v-if="dialog.delete"
        @cancel="dialog.delete = false"
        @done="confirmDelete()"
        >Esta de que no desea registrar el pago de
        <strong>{{ dialog.datos.idcontrato.descripcion }}</strong
        >?</c-dialog-confirmation
      >
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { number } from "@/services/util/number.service";
import { date } from "@/services/util/date.service";
import AutocompleteRepresentante from "@/views/representante/Autocomplete";
export default {
  components: {
    AutocompleteRepresentante,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
    },
  },
  mounted() {
    if (this.editable) this.data = JSON.parse(JSON.stringify(this.props));
    this.fetchContracts();
    setTimeout(() => this.next(0, "-"), 100);
  },
  computed: {
    ...mapGetters("pagos", ["isPagosLoading"]),
    ...mapGetters("contracts", ["getContracts", "isContractsLoading"]),
    title() {
      return this.editable ? "Modificar Pago" : "Registrar Pago";
    },
    total() {
      return this.data.detalle.length > 0
        ? this.data.detalle.reduce((prev, cur) => {
            return prev + cur.precio * cur.cantidad;
          }, 0)
        : 0;
    },
    getContractsFilter() {
      return this.getContracts.filter(
        (idc) => idc.idcontacto.idcontacto === this.data.idcontacto.idcontacto
      );
    },
  },
  methods: {
    ...mapActions("contracts", ["fetchContracts"]),
    ...mapActions("pagos", ["setPagos", "fetchPagos", "setPagosUpdate"]),
    setPrecio(contrato) {
      if (contrato)
        this.detalle.precio = contrato.detalle.reduce((prev, cur) => {
          return prev + cur.idplan.monto;
        }, 0);
    },
    isValid() {
      if (!this.$refs.formDetalle.validate()) return false;
      if (
        this.data.detalle.find((x) => {
          return x.idcontrato.idcontrato === this.detalle.idcontrato.idcontrato;
        })
      ) {
        this.error.contrato = "Este contrato ya existe en el detalle";
        return false;
      }
      return true;
    },
    resetError() {
      this.error.contrato = [];
    },
    async addTable() {
      if (!(await this.isValid())) return null;
      this.data.detalle.push(this.detalle);
      this.detalle = JSON.parse(JSON.stringify(this.detalleDefault));
      this.$refs.formDetalle.resetValidation();
      this.resetError();
      setTimeout(() => this.next(1, "-"), 20);
    },
    editRow(item) {
      this.detalle = JSON.parse(JSON.stringify(item));
    },
    deleteRow(item) {
      this.dialog.delete = true;
      this.dialog.datos = JSON.parse(JSON.stringify(item));
      this.dialog.index = this.data.detalle.indexOf(item);
    },
    confirmDelete() {
      this.data.detalle.splice(this.dialog.index, 1);
      this.dialog.delete = false;
      this.dialog.index = "";
      this.next(1, "-");
    },
    next(id, data) {
      if (data != "")
        try {
          this.$refs[`pago${id + 1}`].focus();
        } catch (error) {
          this.$refs[`pago${id + 1}`].$el.focus();
        }
    },
    toCurrency(value) {
      return number.currency(value);
    },
    cancel() {
      this.$emit("input", false);
    },
    async save() {
      if (!this.$refs.form.validate()) return null;
      const response = this.editable
        ? await this.setPagosUpdate({
            data: this.data,
            id: this.data.idpago_contrato,
          })
        : await this.setPagos(this.data);
      if (response.success) {
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
        setTimeout(() => this.next(0, "-"), 20);
        this.fetchPagos();
        if (this.editable) this.cancel();
      }
    },
  },
  data: () => ({
    rules: [(v) => !!v || "Este campo es obligatorio"],
    error: {
      contrato: [],
    },
    dialog: {
      delete: false,
      index: "",
      datos: null,
    },
    headers: [
      {
        text: "Contrato Empresa",
        align: "start",
        sortable: false,
        value: "idcontrato.descripcion",
      },
      { text: "Precio", value: "precio" },
      { text: "Cantidad Meses", value: "cantidad" },
      { text: "SubTotal", value: "subtotal", align: "end" },
      { text: "Opcion", value: "action", align: "end" },
    ],
    detalle: {
      idcontrato: "",
      precio: "",
      cantidad: "",
    },
    detalleDefault: {
      idcontrato: "",
      precio: "",
      cantidad: "",
    },
    data: {
      idpago_contrato: 0,
      fecha: date.current_date(),
      idcontacto: {
        idcontacto: "",
      },
      comentario: '',
      detalle: [],
    },
    default: {
      idpago_contrato: 0,
      fecha: date.current_date(),
      idcontacto: {
        idcontacto: "",
      },
      comentario: '',
      detalle: [],
    },
  }),
};
</script>

<style>
</style>