<template>
  <v-list color="transparent" dense>
    <div v-for="(item, i) in navbar" :key="i">
      <v-list-item link :to="item.path" v-if="!item.divider">
        <v-list-item-content>
          <v-list-item-title> {{ item.nav }} </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider v-else-if="item.divider"></v-divider>
    </div>
  </v-list>
</template>

<script>
export default {
  data: () => ({
    navbar: [
      {
        nav: "Contratos",
        path: "/contratos",
      },
      {
        nav: "Pagos",
        path: "/pagos",
      },
      {
        divider: true,
      },
      {
        nav: "Representante",
        path: "/representante",
      },
      {
        nav: "Usuarios",
        path: "/usuarios",
      },

      {
        divider: true,
      },
      {
        nav: "Planes",
        path: "/planes",
      },
      {
        nav: "Modulos",
        path: "/modulos",
      },
      {
        nav: "Formularios",
        path: "/formularios",
      },
      {
        divider: true,
      },
      {
        nav: "Rubro",
        path: "/rubros",
      },
      {
        nav: "Estado del Contrato",
        path: "/estadocontrato",
      },
      {
        nav: "Nacionalidad",
        path: "/nacionalidad",
      },
      {
        nav: "Ciudad",
        path: "/ciudad",
      },
      {
        nav: "Departamento",
        path: "/departamento",
      },
    ],
  }),
};
</script>
