<template>
  <v-dialog persistent max-width="500" :value="value">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-form ref="form" @submit.prevent="next(1, data.descripcion)">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                label="Descripcion"
                autocomplete="off"
                :rules="rules"
                ref="departamento1"
                v-model="data.descripcion"
                placeholder="Ingrese una descripcion"
                @keyup.enter="next(1, data.descripcion)"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                :loading="isNacionalidadLoading"
                v-model="data.idnacionalidad"
                :items="getNacionalidad"
                item-value="idnacionalidad"
                item-text="descnacionalidad"
                return-object
                ref="departamento2"
                :rules="[
                  (v) => !!v.idnacionalidad || 'Este campo es obligatorio',
                ]"
                chips
                small-chips
                label="Seleccione la Nacionalidad"
                @keyup.enter="next(2, data.idnacionalidad.idnacionalidad)"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <c-btn-crud-close @click="cancel()"></c-btn-crud-close>
        <v-spacer></v-spacer>
        <c-btn-crud-done ref="departamento3" @click="save()"></c-btn-crud-done>
      </v-card-actions>
      <c-overlay :value="isDepartamentoLoading"></c-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
    },
  },

  mounted() {
    if (this.editable) this.data = JSON.parse(JSON.stringify(this.props));
    this.fetchNacionalidad();
    setTimeout(() => this.next(0, "-"), 20);
  },
  computed: {
    ...mapGetters("departamento", ["isDepartamentoLoading"]),
    ...mapGetters("nacionalidad", ["isNacionalidadLoading", "getNacionalidad"]),
    title() {
      return this.editable ? "Modificar Departamento" : "Registrar Departamento";
    },
  },
  methods: {
    ...mapActions("departamento", ["setDepartamento", "fetchDepartamento", "setDepartamentoUpdate"]),
    ...mapActions("nacionalidad", ["fetchNacionalidad"]),
    cancel() {
      this.$emit("input", false);
    },
    next(id, data) {
      if (data != "")
        try {
          this.$refs[`departamento${id + 1}`].focus();
        } catch (error) {
          this.$refs[`departamento${id + 1}`].$el.focus();
        }
    },
    async save() {
      if (!this.$refs.form.validate()) return null;
      const response = this.editable
        ? await this.setDepartamentoUpdate({
            data: this.data,
            id: this.data.iddepartamento,
          })
        : await this.setDepartamento(this.data);
      if (response.success) {
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
        this.fetchDepartamento();
        this.next(0, "-");
        if (this.editable) this.cancel();
      }
    },
  },
  data: () => ({
    rules: [(v) => !!v || "Este campo es obligatorio"],
    data: {
      iddepartamento: 0,
      descripcion: '',
      idnacionalidad: {
        idnacionalidad: ''
      }
    },
    default: {
      iddepartamento: 0,
      descripcion: '',
      idnacionalidad: {
        idnacionalidad: ''
      }
    },
  }),
};
</script>

<style>
</style>