<template>
  <v-dialog persistent max-width="500" :value="value">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-form ref="form" @submit.prevent="next(1,data.descripcion)">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                label="Descripcion"
                autocomplete="off"
                ref="rubro1"
                :rules="rules"
                v-model="data.descripcion"
                placeholder="Ingrese una descripcion"
                @keyup.enter="next(1,data.descripcion)"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <c-btn-crud-close @click="cancel()"></c-btn-crud-close>
        <v-spacer></v-spacer>
        <c-btn-crud-done ref="rubro2" @click="save()"></c-btn-crud-done>
      </v-card-actions>
      <c-overlay :value="isRubrosLoading"></c-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
    },
  },
  mounted() {
    if (this.editable) this.data = JSON.parse(JSON.stringify(this.props));
    setTimeout(()=>this.next(0,'-'),20);
  },
  computed: {
    ...mapGetters("rubros", ["isRubrosLoading"]),
    title() {
      return this.editable ? "Modificar Rubro" : "Registrar Rubro";
    },
  },
  methods: {
    ...mapActions("rubros", ["setRubros", "fetchRubros", "setRubrosUpdate"]),
    cancel() {
      this.$emit("input", false);
    },
    next(id, data) {
      if (data != "")
        try {
          this.$refs[`rubro${id + 1}`].focus();
        } catch (error) {
          this.$refs[`rubro${id + 1}`].$el.focus();
        }
    },
    async save() {
      if (!this.$refs.form.validate()) return null;
      const response = this.editable
        ? await this.setRubrosUpdate({
            data: this.data,
            id: this.data.idrubro_plan,
          })
        : await this.setRubros(this.data);
      if (response.success) {
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
        setTimeout(()=>this.next(0,'-'),20);
        this.fetchRubros();
        if (this.editable) this.cancel();
      }
    },
  },
  data: () => ({
    rules: [(v) => !!v || "Este campo es obligatorio"],
    data: {
      idrubro_plan: 0,
      descripcion: "",
    },
    default: {
      idrubro_plan: 0,
      descripcion: "",
    },
  }),
};
</script>

<style>
</style>