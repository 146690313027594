<template>
  <v-app id="inspire">
    <v-app-bar app color="white" flat>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-container class="py-0 fill-height">
        <v-spacer></v-spacer>

        <v-spacer></v-spacer>
      </v-container>
      <UserMenu />
    </v-app-bar>

    <v-main class="grey lighten-3">
      <v-row class="mx-1">
        <v-slide-x-transition>
          <v-col cols="12" sm="4" md="2" v-show="drawer">
            <v-sheet rounded="lg"> <Navbar /></v-sheet>
          </v-col>
        </v-slide-x-transition>

        <v-col :md="resize">
          <v-slide-x-transition>
            <v-sheet min-height="90vh" rounded="lg" class="pa-1">
              <transition>
                <keep-alive>
                  <router-view></router-view>
                </keep-alive>
              </transition>
            </v-sheet>
          </v-slide-x-transition>
        </v-col>
      </v-row>
    </v-main>
    <Snackbar />
  </v-app>
</template>

<script>
import Navbar from "@/components/dashboard/Navbar";
import UserMenu from "@/components/dashboard/UserMenu";
import Snackbar from "@/components/widgets/Snackbar";
export default {
  name: "App",
  components: {
    Navbar,
    UserMenu,
    Snackbar
  },
  watch: {
    drawer(val) {
      this.resize = val ? 10 : setTimeout(() => 12, 2);
    },
  },
  data: () => ({
    drawer: null,
    resize: 12,
  }),
};
</script>
