<template>
  <v-dialog persistent max-width="800" :value="value">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-form ref="form">
          <v-row dense>
            <v-col cols="12" md="4">
              <v-text-field
                label="Empresa"
                autocomplete="off"
                ref="contrac1"
                :rules="rules"
                v-model="data.descripcion"
                placeholder="Ingrese el nombre de la empresa"
                @keyup.enter="next(1, data.descripcion)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="RUC o CI"
                ref="contrac2"
                autocomplete="off"
                :rules="rules"
                v-model="data.ruc"
                placeholder="Ingrese el Ruc o CI"
                @keyup.enter="next(2, data.ruc)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <AutocompleteRepresentante
                v-model="data.idcontacto"
                ref="contrac3"
                @keyup.native.enter="next(3, data.idcontacto.idcontacto)"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="Correo"
                autocomplete="off"
                ref="contrac4"
                :rules="rules"
                :error-messages="error.email"
                v-model="data.idusuario.email"
                placeholder="Ingrese el correo electronico del representante"
                @keyup.enter="next(4, data.idusuario.email)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="Telefono"
                autocomplete="off"
                ref="contrac5"
                :rules="rules"
                type="number"
                v-model="data.telefono"
                placeholder="Ingrese un numero telefonico"
                @keyup.native.enter="next(5, data.telefono)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="Nick / Usuario Administrador"
                autocomplete="off"
                ref="contrac6"
                :error-messages="error.username"
                :rules="rules"
                v-model="data.idusuario.username"
                placeholder="Ejemplo: juanperez"
                @keyup.enter="next(6, data.idusuario.username)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                :loading="isEstadoContratoLoading"
                :items="getEstadoContrato"
                ref="contrac7"
                :rules="rules"
                v-model="data.idestado_contrato.idestado_contrato"
                item-value="idestado_contrato"
                item-text="descripcion"
                label="Estado del Contrato"
                placeholder="Seleccione el estado actual del contrato"
                @keyup.enter="next(7, data.idestado_contrato.idestado_contrato)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                :loading="isPeriodoPagoLoading"
                :items="getPeriodoPago"
                ref="contrac8"
                :rules="rules"
                v-model="data.idperiodo_pago.idperiodo_pago"
                item-value="idperiodo_pago"
                item-text="descripcion"
                label="Periodo de Pago"
                placeholder="Seleccione el periodo de pago"
                @keyup.enter="next(8, data.idperiodo_pago.idperiodo_pago)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                :loading="isPlanesLoading"
                :items="planes"
                ref="contrac9"
                :rules="rules"
                v-model="detalle"
                item-value="idplan"
                item-text="descripcion"
                multiple
                return-object
                label="Planes"
                placeholder="Seleccione uno o mas planes"
              >
                <template v-slot:selection="data">
                  <v-chip
                    small
                    v-bind="data.attrs"
                    :input-value="data.selected"
                  >
                    {{ data.item.idplan.descripcion }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template v-if="esobjeto(data.item)">
                    <v-list-item-content
                      v-text="data.item"
                    ></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.idplan.descripcion"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="
                          `Rubro: ${
                            data.item.idplan.idrubro_plan.descripcion
                          } - Precio: ${toCurrency(data.item.idplan.monto)}`
                        "
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <c-btn-crud-close @click="cancel()"> </c-btn-crud-close>
        <v-spacer></v-spacer>
        <c-btn-crud-done @click="validate()"></c-btn-crud-done>
      </v-card-actions>

      <Confirm
        v-if="confirm"
        v-model="confirm"
        :props="data"
        @confirm="save()"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { date } from "@/services/util/date.service";
import { number } from "@/services/util/number.service";
import Confirm from "./Confirm";
import AutocompleteRepresentante from "@/views/representante/Autocomplete";

export default {
  components: {
    AutocompleteRepresentante,
    Confirm,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    props: {
      type: [Object, String],
    },
  },
  mounted() {
    if (this.editable) {
      this.data = JSON.parse(JSON.stringify(this.props));
      this.props.detalle.map((x) => this.detalle.push(x));
    }
    setTimeout(() => this.next(0, "-"), 200);
    this.fetchPlanes();
    this.fetchEstadoContrato();
    this.fetchPeriodoPago();
  },
  computed: {
    ...mapGetters("planes", ["getPlanes", "isPlanesLoading"]),
    ...mapGetters("estadocontrato", [
      "getEstadoContrato",
      "isEstadoContratoLoading",
    ]),
    ...mapGetters("periodopago", ["getPeriodoPago", "isPeriodoPagoLoading"]),

    title() {
      return this.editable ? "Modificar Contrato" : "Registrar Contrato";
    },
  },
  watch: {
    getPlanes(val) {
      let group = [];
      val.map((x) => {
        let isExist = false;
        group.forEach((y) => {
          if (x.idrubro_plan.descripcion === y.header) isExist = true;
        });
        if (!isExist)
          group.push({ header: x.idrubro_plan.descripcion, data: [] });
      });
      val.map((x) => {
        group.map((y) => {
          if (y.header === x.idrubro_plan.descripcion) {
            y.data.push(x);
          }
        });
      });
      let list = [];
      group.map((head) => {
        list.push({ header: head.header });
        head.data.map((plan) => {
          list.push({ idplan: plan });
        });
        list.push({ divider: true });
      });
      this.planes = JSON.parse(JSON.stringify(list));
    },
  },
  methods: {
    ...mapActions("contracts", [
      "setContracts",
      "fetchContracts",
      "setContractsUpdate",
    ]),
    ...mapActions("planes", ["fetchPlanes"]),
    ...mapActions("estadocontrato", ["fetchEstadoContrato"]),
    ...mapActions("periodopago", ["fetchPeriodoPago"]),
    esobjeto(item) {
      return typeof item !== "object";
    },
    cancel() {
      this.$emit("input", false);
    },
    toCurrency(value) {
      return number.currency(value);
    },
    validate() {
      if (!this.$refs.form.validate()) return null;
      this.data.detalle = [];
      this.data.detalle = JSON.parse(JSON.stringify(this.detalle));
      this.confirm = true;
    },
    async save() {
      const response = this.editable
        ? await this.setContractsUpdate({
            data: this.data,
            id: this.data.idcontrato,
          })
        : await this.setContracts(this.data);
      if (response.success) {
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
        this.fetchContracts();
        this.detalle = [];
        this.confirm = false;
        setTimeout(() => this.next(0, "-"), 200);
        if (this.editable) this.cancel();
      } else {
        this.confirm = false;
        this.error = JSON.parse(JSON.stringify(response.data));
      }
    },
    next(id, data) {
      if (data != "")
        try {
          this.$refs[`contrac${id + 1}`].focus();
        } catch (error) {
          this.$refs[`contrac${id + 1}`].$el.focus();
        }
    },
  },
  data: () => ({
    rules: [(v) => !!v || "Este campo es obligatorio"],
    planes: [],
    detalle: [],
    error: {},
    data: {
      idcontrato: 0,
      dia: date.current_day(),
      estado: true,
      fecha: date.current_date(),
      ruc: "",
      descripcion: "",
      telefono: "",
      idcontacto: {
        idcontacto: "",
      },
      idestado_contrato: {
        idestado_contrato: "",
      },
      idperiodo_pago: {
        idperiodo_pago: "",
      },
      idusuario: {
        id: 0,
        username: "",
        email: "",
      },
      detalle: [],
    },
    default: {
      idcontrato: 0,
      dia: date.current_day(),
      estado: true,
      fecha: date.current_date(),
      ruc: "",
      descripcion: "",
      telefono: "",
      idcontacto: {
        idcontacto: "",
      },
      idestado_contrato: {
        idestado_contrato: "",
      },
      idperiodo_pago: {
        idperiodo_pago: "",
      },
      idusuario: {
        id: 0,
        username: "",
        email: "",
      },
      detalle: [],
    },
    confirm: false,
  }),
};
</script>

<style>
</style>