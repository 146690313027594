<template>
  <v-snackbar :color="snackbar.color" v-model="snackbar.show" :timeout="1500">
    {{ snackbar.text }}
     <li v-for="(item,i ) of snackbar.array" :key="i">{{item}}</li>
    <template v-slot:action="{ attrs }">
      <v-btn color="red" text v-bind="attrs" @click="snackbar.show = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters("snackbar", ["getNotification"]),
  },
  watch: {
    getNotification(value) {
      this.snackbar = {
        show: true,
        text: value.message,
        color: value.type,
        array: value.errors
      };
    },
  },
  data: () => ({
    snackbar: {
      show: false,
      text: "",
      color: "",
    },
  }),
};
</script>

<style>
</style>