<template>
  <div>
    <v-toolbar flat dense>
      <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <c-text-table-search v-model="search"></c-text-table-search>
      <v-toolbar-items>
        <v-tabs color="deep-purple accent-4" right v-model="tabs">
          <v-tab v-for="(item, i) in tab" :key="i" :href="'#tab-' + i">{{
            item.title
          }}</v-tab>
        </v-tabs>
        <c-btn-table-reload @click="fetchUsers()"></c-btn-table-reload>
      </v-toolbar-items>
    </v-toolbar>
    <v-tabs-items v-model="tabs">
      <v-tab-item v-for="(item, i) in tab" :key="i" :value="'tab-' + i">
        <Table :active="item.active" :search="search" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Table from "./Table";
export default {
  components: {
    Table,
  },
  created() {
    this.setLayout("App");
  },
  methods: {
    ...mapActions("layout", ["setLayout"]),
    ...mapActions("users", ["fetchUsersActive", "fetchUsersInactive"]),
    fetchUsers() {
      this.fetchUsersActive();
      this.fetchUsersInactive();
    },
  },
  data: () => ({
    tabs: null,
    search: '',
    tab: [
      { active: true, title: "Activo" },
      { active: false, title: "Inactivo" },
    ],
  }),
};
</script>

<style>
</style>