<template>
  <c-dialog-confirmation
    :value="value"
    @cancel="cancel()"
    @done="deletePlan()"
    :loading="isRepresentanteLoading"
    >Desea eliminar el representate <strong>{{ props.desccontacto }}</strong
    >?</c-dialog-confirmation
  >
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: Boolean,
    props: Object,
  },
  computed: {
    ...mapGetters("representante", ["isRepresentanteLoading"]),
  },
  methods: {
    ...mapActions("representante", ["setRepresentanteDelete", "fetchRepresentante"]),
    cancel() {
      this.$emit("input", false);
    },
    async deletePlan() {
      const response = await this.setRepresentanteDelete(this.props.idcontacto);
      if (response.success) {
        this.cancel();
        this.fetchRepresentante();
      }
    },
  },
};
</script>

<style>
</style>