
<template>
  <div>
    <component :is="layout"></component>
  </div>
</template>
<script>
import Login from "./components/layouts/Login";
import App from "./components/layouts/App";
import { mapGetters } from "vuex";
export default {
  components: { Login, App },
  computed: {
    ...mapGetters("layout", ["layout"]),
    
  },
  
};
</script>