import Vue from 'vue'
import VueRouter from 'vue-router'
import { TokenService } from '@/services/storage/tokenStorage.service'



Vue.use(VueRouter)


const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import( /* webpackChunkName: "Login" */ '@/views/login/Login.vue'),
    meta: {
      public: true,
      onlyWhenLoggedOut: true
    }
  },
  {
    path: '/',
    name: 'Home',
    component: () => import( /* webpackChunkName: "Home" */ '@/views/Home.vue')
  },
  {
    path: '/usuarios',
    name: 'Users',
    component: () => import( /* webpackChunkName: "Users" */ '@/views/users/Users.vue')
  },
  {
    path: '/planes',
    name: 'Planes',
    component: () => import( /* webpackChunkName: "Planes" */ '@/views/planes/Planes.vue'),
  },
  {
    path: '/modulos',
    name: 'Modulos',
    component: () => import( /* webpackChunkName: "Modulos" */ '@/views/modulos/Modulos.vue'),
  },
  {
    path: '/formularios',
    name: 'Formularios',
    component: () => import( /* webpackChunkName: "Formularios" */ '@/views/formularios/Formularios.vue'),
  },
  {
    path: '/contratos',
    name: 'Contratos',
    component: () => import( /* webpackChunkName: "Contracts" */ '@/views/contracts/Contracts.vue'),
  },
  {
    path: '/rubros',
    name: 'Rubros',
    component: () => import( /* webpackChunkName: "Rubros" */ '@/views/rubros/Rubros.vue'),
  },
  {
    path: '/estadocontrato',
    name: 'Estado del Contrato',
    component: () => import( /* webpackChunkName: "Estado Contrato" */ '@/views/estadocontrato/EstadoContrato.vue'),
  },
  {
    path: '/pagos',
    name: 'Pagos',
    component: () => import( /* webpackChunkName: "Pagos" */ '@/views/pagos/Pagos.vue'),
  },
  {
    path: '/nacionalidad',
    name: 'Nacionalidad',
    component: () => import( /* webpackChunkName: "Nacionalidad" */ '@/views/nacionalidad/Nacionalidad.vue'),
  },
  {
    path: '/ciudad',
    name: 'Ciudad',
    component: () => import( /* webpackChunkName: "Ciudad" */ '@/views/ciudad/Ciudad.vue'),
  },
  {
    path: '/departamento',
    name: 'Departamento',
    component: () => import( /* webpackChunkName: "Departamento" */ '@/views/departamento/Departamento.vue'),
  },
  {
    path: '/representante',
    name: 'Representante',
    component: () => import( /* webpackChunkName: "Representante" */ '@/views/representante/Representante.vue'),
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public)
  const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut)
  const loggedIn = !!TokenService.getToken();
  if (!isPublic && !loggedIn && to.path !== '/login') {
    return next({
      path: '/login',
      query: {
        redirect: to.fullPath
      }
    });
  }

  if (loggedIn && onlyWhenLoggedOut) {
    return next('/')
  }
  next();
})

export default router
