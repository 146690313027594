<template>
  <v-overlay :value="value" :absolute="absolute">
    <v-progress-circular indeterminate :color="color"></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "primary",
    },
    absolute: {
        type: Boolean,
        defautl: false
    }
  },
};
</script>

<style>
</style>