
<template>
  <v-app>
    <img class="wave" :src="require('./wave.png')" />
    <div class="container">
      <div class="img">
        <img :src="require('./bg.svg')" />
      </div>
      <div class="login-content d-flex">
        <form @submit.prevent="">
          <img style="width: 200px; height: auto" :src="require('./logo2.png')" />
          <h2 class="title">Bienvenido</h2>
          <div class="input-div one">
            <div class="i">
              <i class="fas fa-user"></i>
            </div>
            <div class="div">
              <h5>Usuario</h5>
              <input
                type="text"
                ref="input1"
                v-model="email"
                class="input"
                @keyup.enter="next(1, email)"
              />
            </div>
          </div>
          <div class="input-div pass">
            <div class="i">
              <i class="fas fa-lock"></i>
            </div>
            <div class="div">
              <h5>Contraseña</h5>
              <input
                type="password"
                ref="input2"
                v-model="password"
                class="input"
                @keyup.enter="handleSubmit"
                @click="next(2, password)"
              />
            </div>
          </div>
          <v-alert
            v-if="c != 0"
            :value="true"
            color="error"
            outlined
            text
            class="ma-2"
            >{{ b }}</v-alert
          >
          <v-card-actions class="mt-2">
            <v-row dense>
              <v-checkbox
                v-model="recordar"
                label="Recordar?"
                class="ma-0"
              ></v-checkbox>
            </v-row>
          </v-card-actions>
          <!-- <a href="#">Forgot Password?</a> -->
          <v-progress-circular
            v-if="overlay"
            indeterminate
            color="primary"
            class="ma-2"
          ></v-progress-circular>
          <input
            v-else
            type="submit"
            class="btn"
            ref="input3"
            value="Login"
            @click="handleSubmit"
          />

          <v-row class="ma-0">
            <v-spacer></v-spacer>
            <span class="ma-1 font-weight-medium">Siguenos</span>
            <v-btn
              text
              x-small
              fab
              href="https://www.facebook.com/integralinformatic"
              target="_blank"
            >
              <v-img
                src="https://img.icons8.com/fluent/50/000000/facebook-new.png"
                max-width="30"
              ></v-img>
            </v-btn>
            <v-btn
              text
              x-small
              fab
              href="https://www.instagram.com/integralinformatic/"
              target="_blank"
            >
              <v-img
                src="https://img.icons8.com/fluent/48/000000/instagram-new.png"
                max-width="30"
              ></v-img>
            </v-btn>
          </v-row>
        </form>
      </div>
    </div>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  created() {
    this.setLayout('Login');
  },

  name: "Login",

  data() {
    return {
      email: "",
      password: "",
      recordar: false,
      alert: false,
      overlay: false,
    };
  },
  computed: {
    ...mapGetters("auth", {
      a: "authenticating",
      b: "authenticationError",
      c: "authenticationErrorCode",
    }),
  },
  mounted() {
    setTimeout(() => this.$refs.input1.focus(), 500);
    const inputs = document.querySelectorAll(".input");

    function addcl() {
      let parent = this.parentNode.parentNode;
      parent.classList.add("focus");
    }

    function remcl() {
      let parent = this.parentNode.parentNode;
      if (this.value == "") {
        parent.classList.remove("focus");
      }
    }
    inputs.forEach((input) => {
      input.addEventListener("focus", addcl);
      input.addEventListener("blur", remcl);
    });
  },
  methods: {
    ...mapActions('layout',["setLayout"]),
    ...mapActions("auth", ["login"]),
    next(id, data) {
      if (data != "") return;
      this.$refs[`input${id}`].focus();
    },
    async handleSubmit() {
      // Perform a simple validation that email and password have been typed in
      if (this.email != "" && this.password != "") {
        this.overlay = true;
        await this.login({
          email: this.email,
          password: this.password,
          recordar: this.recordar,
        });
        this.overlay = false;
      } else {
        this.$notify({
          group: "auth",
          type: "error",
          text: "Complete los campos!",
        });
      }
    },
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.wave {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100%;
}

.container {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 7rem;
  padding: 0 2rem;
  z-index: 1;
}

.img {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.login-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

.img img {
  width: 500px;
}

form {
  width: 360px;
}

.login-content img {
  height: 100px;
}

.login-content h2 {
  margin: 15px 0;
  color: #333;
  text-transform: uppercase;
  font-size: 2.9rem;
}

.login-content .input-div {
  position: relative;
  display: grid;
  grid-template-columns: 7% 93%;
  margin: 25px 0;
  padding: 5px 0;
  border-bottom: 2px solid #d9d9d9;
}

.login-content .input-div.one {
  margin-top: 0;
}

.i {
  color: #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.i i {
  transition: 0.3s;
}

.input-div > div {
  position: relative;
  height: 45px;
}

.input-div > div > h5 {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
  font-size: 18px;
  transition: 0.3s;
}

.input-div:before,
.input-div:after {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 0%;
  height: 2px;
  background-color: #38d39f;
  transition: 0.4s;
}

.input-div:before {
  right: 50%;
}

.input-div:after {
  left: 50%;
}

.input-div.focus:before,
.input-div.focus:after {
  width: 50%;
}

.input-div.focus > div > h5 {
  top: -5px;
  font-size: 15px;
}

.input-div.focus > .i > i {
  color: #38d39f;
}

.input-div > div > input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background: none;
  padding: 0.5rem 0.7rem;
  font-size: 1.2rem;
  color: #555;
  font-family: "poppins", sans-serif;
}

.input-div.pass {
  margin-bottom: 4px;
}

a {
  display: block;
  text-align: right;
  text-decoration: none;
  color: #999;
  font-size: 0.9rem;
  transition: 0.3s;
}

a:hover {
  color: #38d39f;
}

.btn {
  display: block;
  width: 100%;
  height: 50px;
  border-radius: 25px;
  outline: none;
  border: none;
  background-image: linear-gradient(to right, #32be8f, #38d39f, #32be8f);
  background-size: 200%;
  font-size: 1.2rem;
  color: #fff;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  margin: 1rem 0;
  cursor: pointer;
  transition: 0.5s;
}
.btn:hover {
  background-position: right;
}

@media screen and (max-width: 1050px) {
  .container {
    grid-gap: 5rem;
  }
}

@media screen and (max-width: 1000px) {
  form {
    width: 290px;
  }

  .login-content h2 {
    font-size: 2.4rem;
    margin: 8px 0;
  }

  .img img {
    width: 400px;
  }
}

@media screen and (max-width: 900px) {
  .container {
    grid-template-columns: 1fr;
  }

  .img {
    display: none;
  }

  .wave {
    display: none;
  }

  .login-content {
    justify-content: center;
  }
}
</style>