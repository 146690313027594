<template>
  <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-avatar
          v-bind="attrs"
          v-on="on"
          class="ma-0"
          color="grey darken-1"
          size="40"
          ><v-icon dark> mdi-account-circle </v-icon></v-avatar
        >
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-avatar class="ma-0" color="grey darken-1" size="40"
                ><v-icon dark> mdi-account-circle </v-icon></v-avatar
              >
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>Rony Parra</v-list-item-title>
              <v-list-item-subtitle>Frontend Developer</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list>
          <v-list-item @click="closeSession()">
            <v-list-item-title>Salir</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions("auth", ["logout"]),
    closeSession() {
      this.logout();
    },
  },
  data: () => ({
      menu: false
  }),

};
</script>