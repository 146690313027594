<template>
  <v-btn color="primary" small text @click="$emit('click')">Editar</v-btn>
</template>

<script>
export default {

}
</script>

<style>

</style>