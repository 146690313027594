<template>
  <div>
    <v-autocomplete
      :loading="isRepresentanteLoading"
      :items="getRepresentante"
      :filter="customFilter"
      :rules="rules"
      :value="value"
      ref="input"
      @input="$emit('input', $event)"
      return-object
      item-value="idcontacto"
      item-text="desccontacto"
      label="Representante de la empresa"
      placeholder="Seleccione el representante"
    >
      <template slot="item" slot-scope="data">
        <v-list-item-content>
          <v-list-item-title
            ><strong>{{ data.item.desccontacto }}</strong> - Razon Social
          </v-list-item-title>
          <v-list-item-subtitle
            >Ruc: <strong>{{ data.item.ruccontacto }}</strong> / Cod:
            <strong>{{ data.item.idcontacto }}</strong>
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
      <template v-slot:no-data>
        <v-list-item dense @click="formulario = true">
          <v-list-item-content>
            <v-list-item-title
              >Desea registrar este representante?</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
    <Crud v-if="formulario" v-model="formulario" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Crud from "./Crud";
export default {

  components: {
    Crud,
  },
  props: {
    rules: Array,
    value: [Object,String,Number]
  },
  mounted() {
    this.fetchRepresentante();
  },
  computed: {
    ...mapGetters("representante", [
      "getRepresentante",
      "isRepresentanteLoading",
    ]),
  },
  methods: {
    ...mapActions("representante", ["fetchRepresentante"]),
    customFilter(item, queryText) {
      const textOne = item.idcontacto.toString();
      const textTwo = item.desccontacto.toLowerCase();
      const textTree = item.ruccontacto.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1 ||
        textTree.indexOf(searchText) > -1
      );
    },
    focus() {
      this.$refs.input.focus();
    },
  },
  data: () => ({
    formulario: false,
  }),
};
</script>

<style>
</style>