import {SET_LOADING, SET_REPRESENTANTE, SET_REPRESENTANTE_FIREBASE} from './constants'
export default {
  [SET_REPRESENTANTE](state, request) {
    state.representante = request;
  },
  [SET_REPRESENTANTE_FIREBASE](state, request) {
    state.representante_firebase = request;
  },
  [SET_LOADING](state, request) {
    state.isLoading = request;
  },
}