
const TOKEN_KEY = 'access_token'
const REFRESH_TOKEN_KEY = 'refresh_token'
export const TokenService = {
    getToken() {
        return sessionStorage.getItem(TOKEN_KEY) || localStorage.getItem(TOKEN_KEY) 
    },

    saveToken(accessToken, recordar) {
        if(recordar) return localStorage.setItem(TOKEN_KEY, accessToken)
        if(!recordar) return sessionStorage.setItem(TOKEN_KEY, accessToken)
    },
    removeToken() {
        sessionStorage.removeItem(TOKEN_KEY)
        localStorage.removeItem(TOKEN_KEY)
    },

    getRefreshToken() {
        return sessionStorage.getItem(REFRESH_TOKEN_KEY)
    },

    saveRefreshToken(refreshToken) {
        sessionStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
    },

    removeRefreshToken() {
        sessionStorage.removeItem(REFRESH_TOKEN_KEY)
    }

};