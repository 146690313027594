const date = {
  dmy(date) {
    if (!date) return null;
    const [year, month, day] = date.split("-");
    return `${day}-${month}-${year}`;
  },
  ymd(date) {
    if (!date) return null;
    const [day, month, year] = date.split("-");
    return `${year}-${month}-${day}`;
  },
  suma(dias) {
    let fecha = new Date();
    fecha.setDate(fecha.getDate() + dias);
    return fecha.toISOString().substr(0, 10)
  },
  sumaM(data, mes) {
    let fecha = new Date(data);
    fecha.setMonth(fecha.getMonth() + mes);
    return fecha.toISOString().substr(0, 10)
  },
  resta(dias) {
    let fecha = new Date();
    fecha.setDate(fecha.getDate() - dias);
    return fecha.toISOString().substr(0, 10)
  },
  current_time(){
    let hour, minute, second;
    const today = new Date();
    today.getHours().toString().length === 1 ? hour = '0' +today.getHours() : hour  = today.getHours();
    today.getMinutes().toString().length === 1 ? minute = '0' + today.getMinutes() : minute =  today.getMinutes();
    today.getSeconds().toString().length === 1 ? second = '0' + today.getSeconds() : second = today.getSeconds();
    return hour + ":" + minute + ":" + second;
  },
  current_date() {
    return new Date().toISOString().substr(0, 10);
  },
  current_first_date(){
     return new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().substr(0, 10);
  },
  current_last_date(){
    return new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().substr(0, 10);
 },
  current_day() {
    const date = new Date();
    return date.getDate();
  }
};

export {
  date
}