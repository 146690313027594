<template>
  <c-dialog-confirmation
    :value="value"
    @cancel="cancel()"
    @done="deletePlan()"
    :loading="isFormulariosLoading"
    >Desea eliminar el modulo <strong>{{ props.descrol }}</strong
    >?</c-dialog-confirmation
  >
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: Boolean,
    props: Object,
  },
  computed: {
    ...mapGetters("modulos", ["isModulosLoading"]),
  },
  methods: {
    ...mapActions("modulos", ["setModulosDelete", "fetchModulos"]),
    cancel() {
      this.$emit("input", false);
    },
    async deletePlan() {
      const response = await this.setModulosDelete(this.props.idrol);
      if (response.success) {
        this.cancel();
        this.fetchModulos();
      }
    },
  },
};
</script>

<style>
</style>