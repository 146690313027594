<template>
  <c-dialog-confirmation
    :value="value"
    @cancel="cancel()"
    @done="deletePlan()"
    :loading="isRubrosLoading"
    >Desea eliminar el plan <strong>{{ props.descripcion }}</strong
    >?</c-dialog-confirmation
  >
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: Boolean,
    props: Object,
  },
  computed: {
    ...mapGetters("rubros", ["isRubrosLoading"]),
  },
  methods: {
    ...mapActions("rubros", ["setRubrosDelete", "fetchRubros"]),
    cancel() {
      this.$emit("input", false);
    },
    async deletePlan() {
      const response = await this.setRubrosDelete(this.props.idrubro_plan);
      if (response.success) {
        this.cancel();
        this.fetchRubros();
      }
    },
  },
};
</script>

<style>
</style>