import { get, post, put, del } from "@/services/api/api.service";
import { SET_LOADING, SET_CIUDAD, url } from "./constants";
export default {
  async fetchCiudad(
    { commit, dispatch },
    { idnacionalidad, iddepartamento, search }
  ) {
    if(!idnacionalidad && !iddepartamento) return null;
    let urlParams = url + "?";
    if (idnacionalidad)
      urlParams = urlParams + "nacionalidad=" + idnacionalidad + "&";
    if (iddepartamento)
      urlParams = urlParams + "departamento=" + iddepartamento + "&";
    urlParams = urlParams + "buscar=" + search;
    commit(SET_LOADING, true);
    try {
      const response = await get(urlParams);
      commit(SET_CIUDAD, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(SET_LOADING, false);
      throw e;
    }
    commit(SET_LOADING, false);
  },
  async setCiudad({ commit, dispatch }, data) {
    commit(SET_LOADING, true);
    const response = await post(url, data);
    dispatch("snackbar/setNotification", response, { root: true });
    commit(SET_LOADING, false);
    return response;
  },
  async setCiudadUpdate({ commit, dispatch }, { data, id }) {
    commit(SET_LOADING, true);
    const response = await put(`${url}${id}/`, data);
    dispatch("snackbar/setNotification", response, { root: true });
    commit(SET_LOADING, false);
    return response;
  },
  async setCiudadDelete({ commit, dispatch }, id) {
    commit(SET_LOADING, true);
    const response = await del(`${url}${id}/`);
    dispatch("snackbar/setNotification", response, { root: true });
    commit(SET_LOADING, false);
    return response;
  },
};
