<template>
  <v-dialog :value="value" persistent max-width="350">
    <v-card>
      <v-card-title>
        <v-icon left>mdi-alert-circle-outline</v-icon>
        <span class="title font-weight-light">Ventana de Confirmacion</span>
      </v-card-title>
      <v-card-text> <slot /></v-card-text>
      <v-card-actions>
        <c-btn-crud-close @click="$emit('cancel')"></c-btn-crud-close>
        <v-spacer></v-spacer>
        <c-btn-crud-done @click="$emit('done')"></c-btn-crud-done>
      </v-card-actions>
      <c-overlay :value="loading"></c-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>