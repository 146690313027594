<template>
  <v-text-field
    v-model="mask"
    :id="id"
    :rules="rules"
    :label="this.label"
    :readonly="readonly"
    :outlined="outlined"
    v-on:focus="$emit('focus')"
    :dense="dense"
    :hide-details="hideDetails"
    autocomplete="off"
    @keyup.enter="completeDate()"
  ></v-text-field>
</template>

<script>
import { date } from "@/services/util/date.service";
export default {
  props: {
    "hide-details": {
      type: Boolean,
      default: false
    },
    label: String,
    dense: {
      type: Boolean,
      default: false
    },
    value: {
      type: String
    },
    id: String,
    rules: {
      type: [Array]
    },
    outlined: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    
  },
  watch: {
    value(val) {
      this.convertValueToMask(val);
    },
    mask(val) {
      this.setDate(val);
      let [day, month, year] = this.getDateParts(val);
      if (day.length === 2 && month === "") {
        day = day + "-";
        this.setDate(day);
      }
      if (month.length === 2 && year === "") {
        month = month + "-";
        this.setDate(`${day}-${month}`);
      }
      if (day.length > 2 && month.length > 0) {
        let dayParts = day.split("");
        day = dayParts[0] + "" + dayParts[1];
        month = dayParts[2] + "" + month;
        this.setDate(`${day}-${month}-${year}`);
      }
      if (month.length > 2 && year.length > 0) {
        let monthParts = month.split("");
        month = monthParts[0] + "" + monthParts[1];
        year = monthParts[2] + "" + year;
        this.setDate(`${day}-${month}-${year}`);
      }
      if (year.length > 4) {
        let yearParts = year.split("");
        year =
          yearParts[0] +
          "" +
          yearParts[1] +
          "" +
          yearParts[2] +
          "" +
          yearParts[3];
        this.setDate(`${day}-${month}-${year}`);
      }
    }
  },
  methods: {
    setDate(val) {
      this.mask = val;
      const [day, month, year] = this.getDateParts(val);
      this.$emit("input", `${year}-${month}-${day}`);
    },
    getDateParts(val) {
      let [day, month, year] = val
        .split(" ")
        .join("")
        .replace(/[/]/gi, "-")
        .split("-");
      return [day || "", month || "", year || ""];
    },
    convertValueToMask(val) {
      if(val=== null) return this.mask = ''
      let [year, month, day] = this.getDateParts(val);
      if (year != "" && month != "" && day != "") this.mask = date.dmy(val);
    },
    completeDate() {
      let [day, month, year] = this.getDateParts(this.mask);
      if (day.length === 0) return;
      if (day.length === 1) day = "0" + day;
      if (month.length === 0) return;
      if (month.length === 1) month = "0" + month;
      switch (year.length) {
        case 0:
          year = new Date().getFullYear();
          break;
        case 1:
          year = "200" + year;
          break;
        case 2:
          year = "20" + year;
          break;
        case 3:
          year = "2" + year;
          break;
        default:
          break;
      }
      this.setDate(`${day}-${month}-${year}`);
    }
  },
  data() {
    return {
      validar: [v => !!v || "Campo requerido"],
      mask: ""
    };
  },
  mounted() {
    if (this.value != null) {
       this.convertValueToMask(this.value);
    }
  },

  computed: {
    computed_val() {
      return this.value != null ? this.value : date.current_date("en");
    }
  }
};
</script>