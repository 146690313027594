<template>
  <div>
    <v-data-table
      :headers="headers"
      :search="search"
      :loading="isFormulariosLoading"
      :items="getFormularios"
      :items-per-page="10"
    >
      <template v-slot:top>
        <v-toolbar flat dense>
          <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <c-text-table-search v-model="search"></c-text-table-search>

          <v-toolbar-items>
            <c-btn-table-add @click="crud.add = true"></c-btn-table-add>
            <c-btn-table-reload
              @click="fetchFormularios()"
            ></c-btn-table-reload>
          </v-toolbar-items>
        </v-toolbar>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <c-btn-table-edit @click="editPlan(item)"></c-btn-table-edit>
        <c-btn-table-delete @click="deletePlan(item)"></c-btn-table-delete>
      </template>
    </v-data-table>
    <Crud v-model="crud.add" v-if="crud.add" />
    <Crud editable v-model="crud.edit" v-if="crud.edit" :props="crud.data" />
    <Delete v-model="crud.delete" v-if="crud.delete" :props="crud.data" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Crud from "./Crud";
import Delete from "./Delete";
export default {
  components: {
    Crud,
    Delete,
  },
  created() {
    this.setLayout("App");
  },
  mounted() {
    this.fetchFormularios();
  },
  computed: {
    ...mapGetters("formularios", ["getFormularios", "isFormulariosLoading"]),
  },
  methods: {
    ...mapActions("layout", ["setLayout"]),
    ...mapActions("formularios", ["fetchFormularios"]),
    editPlan(item) {
      this.crud.edit = true;
      this.crud.data = JSON.parse(JSON.stringify(item));
    },
    deletePlan(item) {
      this.crud.delete = true;
      this.crud.data = JSON.parse(JSON.stringify(item));
    },
  },
  data: () => ({
    search: "",
    headers: [
      {
        text: "Codigo",
        align: "start",
        value: "idformulario",
      },
      { text: "Descripcion", value: "descformulario" },
      { text: "Dependencias", value: "dependencias" },
      { text: "Opciones", value: "action", align: "end", sortable: false },
    ],
    crud: {
      add: false,
      edit: false,
      delete: false,
      data: null,
    },
  }),
};
</script>

<style>
</style>