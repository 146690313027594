<template>
  <v-container>

    
        

  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  created() {
    this.setLayout("App");
  },
  methods: {
    ...mapActions("layout", ["setLayout"]),
  },
  data: () => ({}),
};
</script>