<template>
  <v-btn color="grey lighten-3" @click="$emit('click')"
    ><v-icon>mdi-autorenew</v-icon></v-btn
  >
</template>

<script>
export default {};
</script>

<style>
</style>