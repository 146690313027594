
export default {
  getRepresentante: (state) => {
    return state.representante
  },
  getRepresentanteFirebase: (state) => {
    return state.representante_firebase
  },
  isRepresentanteLoading: (state) => {
    return state.isLoading
  }
}
