<template>
  <v-dialog persistent max-width="800" :value="value">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-form ref="form">
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-switch
                v-model="data.mensual"
                :label="`Cobro: ${data.mensual ? 'Mensual' : 'Anual'}`"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="6">
              <v-switch
                v-model="data.principal"
                :label="`Recomendado? ${data.principal ? 'Si' : 'No'}`"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Descripcion"
                autocomplete="off"
                ref="plan1"
                :rules="rules"
                v-model="data.descripcion"
                placeholder="Ingrese una descripcion"
                @keyup.enter="next(1, data.descripcion)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <c-text-currency
                label="Precio"
                autocomplete="off"
                :rules="rules"
                ref="plan2"
                type="number"
                v-model="data.monto"
                placeholder="Ingrese un precio"
                @keyup.native.enter="next(2, data.monto)"
              ></c-text-currency>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                :loading="isRubrosLoading"
                v-model="data.idrubro_plan"
                :items="getRubros"
                ref="plan3"
                item-value="idrubro_plan"
                item-text="descripcion"
                return-object
                :rules="[
                  (v) => !!v.idrubro_plan || 'Rubro del Negocio obligatorio',
                ]"
                chips
                small-chips
                label="Seleccione el Rubro del Negocio"
                @keyup.enter="next(3, data.idrubro_plan.idrubro_plan)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                :loading="isMonedaLoading"
                v-model="data.idmoneda"
                :items="getMoneda"
                ref="plan4"
                item-value="idmoneda"
                item-text="descmoneda"
                return-object
                :rules="[(v) => !!v.idmoneda || 'Obligatorio']"
                chips
                small-chips
                label="Seleccione la Moneda"
                @keyup.enter="next(3, data.idmoneda.idmoneda)"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-textarea color="teal" v-model="detalle_json">
                <template v-slot:label>
                  <div>Funciones <small>(JSON)</small></div>
                </template>
              </v-textarea>
            </v-col>
            <v-btn text outlined block color="teal" @click="formatJson()"
              >FORMATEAR JSON</v-btn
            >
          </v-row>
        </v-form>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <c-btn-crud-close @click="cancel()"></c-btn-crud-close>
        <v-spacer></v-spacer>
        <c-btn-crud-done ref="plan4" @click="save()"></c-btn-crud-done>
      </v-card-actions>
      <c-overlay :value="isPlanesLoading"></c-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
    },
  },
  mounted() {
    if (this.editable) {
      this.detalle_json = JSON.stringify(this.props.detalle);
      this.data = JSON.parse(JSON.stringify(this.props));
      this.formatJson();
    }
    this.fetchRubros();
    this.fetchMoneda();
    setTimeout(() => this.next(0, "-"), 20);
  },
  computed: {
    ...mapGetters("planes", ["isPlanesLoading"]),
    ...mapGetters("moneda", ["isMonedaLoading", "getMoneda"]),
    ...mapGetters("rubros", ["isRubrosLoading", "getRubros"]),
    title() {
      return this.editable ? "Modificar Plan" : "Registrar Plan";
    },
  },
  methods: {
    ...mapActions("planes", ["setPlanes", "fetchPlanes", "setPlanesUpdate"]),
    ...mapActions("moneda", ["fetchMoneda"]),
    ...mapActions("rubros", ["fetchRubros"]),
    next(id, data) {
      if (data != "")
        try {
          this.$refs[`plan${id + 1}`].focus();
        } catch (error) {
          this.$refs[`plan${id + 1}`].$el.focus();
        }
    },
    formatJson() {
      try {
        this.detalle_json = JSON.stringify(
          JSON.parse(this.detalle_json),
          null,
          4
        );
        this.data.detalle = JSON.parse(this.detalle_json);
      } catch (error) {
        this.json_error = error;
      }
    },
    cancel() {
      this.$emit("input", false);
    },
    async save() {
      if (!this.$refs.form.validate()) return null;
      this.formatJson();
      const response = this.editable
        ? await this.setPlanesUpdate({ data: this.data, id: this.data.idplan })
        : await this.setPlanes(this.data);
      if (response.success) {
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
        this.next(0, "-");
        this.fetchPlanes();
        if (this.editable) this.cancel();
      }
    },
  },
  data: () => ({
    rules: [(v) => !!v || "Este campo es obligatorio"],
    detalle_json: "",
    json_error: "",
    data: {
      idplan: 0,
      principal: false,
      mensual: true,
      descripcion: "",
      monto: "",
      idrubro_plan: {
        idrubro_plan: "",
      },
      idmoneda: {
        idmoneda: "",
      },
      incluye: [],
      detalle: [],
    },
    default: {
      idplan: 0,
      principal: false,
      mensual: false,
      descripcion: "",
      monto: "",
      idrubro_plan: {
        idrubro_plan: "",
      },
      idmoneda: {
        idmoneda: "",
      },
      incluye: [],
      detalle: [],
    },
  }),
};
</script>

<style></style>
