import { get, post, put, del } from "@/services/api/api.service";
import { SET_LOADING, SET_REPRESENTANTE, SET_REPRESENTANTE_FIREBASE, url } from './constants'
export default {
    async fetchRepresentante({ commit, dispatch }) {
        commit(SET_LOADING, true);
        try {
            const response = await get(url);
            commit(SET_REPRESENTANTE, response);
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true });
            commit(SET_LOADING, false);
            throw e
        }
        commit(SET_LOADING, false);
    },
    async setRepresentante({ commit, dispatch }, data) {
        commit(SET_LOADING, true);
        const response = await post(url, data);
        dispatch('snackbar/setNotification', response, { root: true })
        commit(SET_LOADING, false);
        return response
    },
    async setRepresentanteUpdate({ commit, dispatch }, { data, id }) {
        commit(SET_LOADING, true);
        const response = await put(`${url}${id}/`, data);
        dispatch('snackbar/setNotification', response, { root: true })
        commit(SET_LOADING, false);
        return response
    },
    async setRepresentanteDelete({ commit, dispatch }, id) {
        commit(SET_LOADING, true);
        const response = await del(`${url}${id}/`);
        dispatch('snackbar/setNotification', response, { root: true })
        commit(SET_LOADING, false)
        return response;
    },
    async fetchRepresentanteFirebase({ commit }, ruc) {
        commit(SET_LOADING, true);
        try {
            const response = await get('/apirepresentante/?ruccontacto=' + ruc);
            if (response != '') {
                commit(SET_REPRESENTANTE_FIREBASE, { origin: 'db', data: response[0] });
            }
            else {
                const firebase = await get('/apicontactofire/?ruccontacto=' + ruc);
                firebase.error != 0 ? (
                    commit(SET_REPRESENTANTE_FIREBASE, { origin: 'firebase', data: { docucontactos: firebase.dv, desccontacto: firebase.razonsocial } })
                ) : (commit(SET_REPRESENTANTE_FIREBASE, { origin: 'backend', data: { docucontactos: firebase.calc } }))
            }

        } catch (error) {
            commit(SET_REPRESENTANTE_FIREBASE, null);
            console.log(error)
        }
        commit(SET_LOADING, false);
    }
}
