// https://vuex.vuejs.org/en/actions.html
/* eslint-disable */
import { UserService, AuthenticationError } from '@/services/user/user.service'
import router from '@/router'
export default {
    async login({ commit }, { email, password, recordar }) {
        commit('loginRequest');

        try {
            const token = await UserService.login(email, password, recordar);

            commit('loginSuccess', token)
            // Redirect the user to the page he first tried to visit or to the home view

            router.push(router.history.current.query.redirect || '/');

            return true
        } catch (e) {
            if (e instanceof AuthenticationError) {
                commit('loginError', { errorCode: e.errorCode, errorMessage: e.message })
            }

            return false
        }
    },

    logout({ commit }) {
        UserService.logout()
        commit('logoutSuccess')
        //router.push('/login')
        location.reload();
    },
    refreshToken({ commit, state }) {
        // If this is the first time the refreshToken has been called, make a request
        // otherwise return the same promise to the caller
        if (!state.refreshTokenPromise) {
            const p = UserService.refreshToken()
            commit('refreshTokenPromise', p)

            // Wait for the UserService.refreshToken() to resolve. On success set the token and clear promise
            // Clear the promise on error as well.
            p.then(
                response => {
                    commit('refreshTokenPromise', null)
                    commit('loginSuccess', response)
                },
                error => {
                    commit('refreshTokenPromise', null)
                }
            )
        }

        return state.refreshTokenPromise
    }
}
