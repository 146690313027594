<template>
  <v-dialog persistent max-width="600" :value="value">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-form ref="form" @submit.prevent="next(1, data.descformulario)">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                label="Descripcion"
                autocomplete="off"
                ref="form1"
                :rules="rules"
                v-model="data.descformulario"
                placeholder="Ingrese el nombre del Formulario"
                @keyup.enter="next(1, data.descformulario)"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="data.dependencias"
                :items="getFormularios"
                ref="form2"
                item-value="idformulario"
                item-text="descformulario"
                chips
                small-chips
                label="Seleccione las dependencias"
                multiple
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-textarea color="teal" v-model="permiso_json">
                <template v-slot:label>
                  <div>Funciones <small>(JSON)</small></div>
                </template>
              </v-textarea>
            </v-col>
            <v-btn text outlined block color="teal" @click="formatJson()"
              >FORMATEAR JSON</v-btn
            >
          </v-row>
        </v-form>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <c-btn-crud-close @click="cancel()"></c-btn-crud-close>
        <v-spacer></v-spacer>
        <c-btn-crud-done @click="save()"></c-btn-crud-done>
      </v-card-actions>
      <c-overlay :value="isFormulariosLoading"></c-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
    },
  },
  mounted() {
    if (this.editable) {
      this.data = JSON.parse(JSON.stringify(this.props));
      this.permiso_json = JSON.stringify(this.data.permiso_json, null, 4);
    }
    setTimeout(() => this.next(0, "-"), 20);
  },
  computed: {
    ...mapGetters("formularios", ["isFormulariosLoading", "getFormularios"]),
    title() {
      return this.editable ? "Modificar Formulario" : "Registrar Formulario";
    },
  },
  methods: {
    ...mapActions("formularios", [
      "setFormularios",
      "fetchFormularios",
      "setFormulariosUpdate",
    ]),
    formatJson() {
      try {
        this.permiso_json = JSON.stringify(
          JSON.parse(this.permiso_json),
          null,
          4
        );
        this.data.permiso_json = JSON.parse(this.permiso_json);
      } catch (error) {
        this.json_error = error;
      }
    },
    next(id, data) {
      if (data != "")
        try {
          this.$refs[`form${id + 1}`].focus();
        } catch (error) {
          this.$refs[`form${id + 1}`].$el.focus();
        }
    },
    cancel() {
      this.$emit("input", false);
    },
    async save() {
      if (!this.$refs.form.validate()) return null;
      this.formatJson();
      const response = this.editable
        ? await this.setFormulariosUpdate({
            data: this.data,
            id: this.data.idformulario,
          })
        : await this.setFormularios(this.data);
      if (response.success) {
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
        this.fetchFormularios();
        if (this.editable) this.cancel();
      }
    },
  },
  data: () => ({
    rules: [(v) => !!v || "Este campo es obligatorio"],
    permiso_json: "",
    json_error: "",
    data: {
      idformulario: 0,
      descformulario: "",
      permiso_json: "",
      dependencias: null,
    },
    default: {
      idformulario: 0,
      descformulario: "",
      permiso_json: "",
      dependencias: null,
    },
  }),
};
</script>

<style>
</style>