import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import Vuex from "vuex";
import {TokenService} from './services/storage/tokenStorage.service';
import ApiService from './services/api/apiconfig.service';
import './components/vuetify'
//const VUE_APP_ROOT_API = "http://localhost:8000/api/";
const VUE_APP_ROOT_API = "https://administracion.integral.com.py/api/"

Vue.use(Vuex);
Vue.config.productionTip = false

ApiService.init(VUE_APP_ROOT_API);


if (TokenService.getToken()) {
  ApiService.setHeader()
}

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
