<template>
  <v-dialog :value="value" max-width="400">
    <v-card>
      <v-card-title>
        <v-icon left>mdi-alert-circle-outline</v-icon>
        <span class="title font-weight-normal">Desea crear el Contrato?</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-2">
        <div class="text-subtitle-2">
          Empresa: <strong>{{ props.descripcion }}</strong>
        </div>
        <div class="text-subtitle-2">
          Ruc: <strong>{{ props.ruc }}</strong>
        </div>
        <div class="text-subtitle-2">
          Representante: <strong>{{ props.idcontacto.desccontacto }}</strong>
        </div>
        <div class="text-subtitle-2">
          Telefono: <strong>{{ props.telefono }}</strong>
        </div>
        <div class="text-subtitle-2">
          Correo: <strong>{{ props.idusuario.email }}</strong>
        </div>
        <div class="text-subtitle-2">
          Admin: <strong>{{ props.idusuario.username }}</strong>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Plan</th>
              <th class="text-end">Precio</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in props.detalle" :key="i">
              <td>{{ item.idplan.descripcion }}</td>
              <td class="text-end">{{ toCurrency(item.idplan.monto) }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold">Total Plan</td>
              <td class="font-weight-bold text-end">{{ total }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-card-actions>
        <c-btn-crud-close @click="$emit('input', false)"></c-btn-crud-close>
        <v-spacer></v-spacer>
        <c-btn-crud-done @click="$emit('confirm')"></c-btn-crud-done>
      </v-card-actions>
      <c-overlay :value="isContractsLoading"></c-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
import { number } from "@/services/util/number.service";
import { mapGetters } from "vuex";
export default {
  props: {
    value: Boolean,
    props: Object,
  },
  methods: {
    toCurrency(value) {
      return number.currency(value);
    },
  },

  computed: {
    ...mapGetters("contracts", ["isContractsLoading"]),
    total() {
      var sum = 0;
      this.props.detalle.map((plan) => {
        sum = sum + plan.idplan.monto;
      });
      return this.toCurrency(sum);
    },
  },
};
</script>

<style>
</style>