<template>
  <v-dialog persistent max-width="500" :value="value">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-form ref="form" @submit.prevent="next(1,data.descripcion)">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                label="Descripcion"
                autocomplete="off"
                ref="estadoc1"
                :rules="rules"
                v-model="data.descripcion"
                placeholder="Ingrese una descripcion"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-color-picker hide-inputs v-model="data.color"></v-color-picker>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <c-btn-crud-close @click="cancel()"></c-btn-crud-close>
        <v-spacer></v-spacer>
        <c-btn-crud-done ref="estadoc2" @click="save()"></c-btn-crud-done>
      </v-card-actions>
      <c-overlay :value="isEstadoContratoLoading"></c-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
    },
  },
  mounted() {
    if (this.editable) this.data = JSON.parse(JSON.stringify(this.props));
    setTimeout(()=>this.next(0,'-'),20);
  },
  computed: {
    ...mapGetters("estadocontrato", ["isEstadoContratoLoading"]),
    title() {
      return this.editable
        ? "Modificar Estado del Contrato"
        : "Registrar Estado del Contrato";
    },
  },
  methods: {
    ...mapActions("estadocontrato", [
      "setEstadoContrato",
      "fetchEstadoContrato",
      "setEstadoContratoUpdate",
    ]),
    cancel() {
      this.$emit("input", false);
    },
        next(id, data) {
      if (data != "")
        try {
          this.$refs[`estadoc${id + 1}`].focus();
        } catch (error) {
          this.$refs[`estadoc${id + 1}`].$el.focus();
        }
    },
    async save() {
      if (!this.$refs.form.validate()) return null;
      const response = this.editable
        ? await this.setEstadoContratoUpdate({
            data: this.data,
            id: this.data.idestado_contrato,
          })
        : await this.setEstadoContrato(this.data);
      if (response.success) {
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
        this.fetchEstadoContrato();
        setTimeout(()=>this.next(0,'-'),20);
        if (this.editable) this.cancel();
      }
    },
  },
  watch:{
    color(val){
      console.log(val)
    }
  },
  data: () => ({
    rules: [(v) => !!v || "Este campo es obligatorio"],
    data: {
      idestado_contrato: 0,
      descripcion: "",
      color: ''
    },
    default: {
      idestado_contrato: 0,
      descripcion: "",
      color: ''
    },
  }),
};
</script>

<style>
</style>